import React from "react"
import Helmet from "react-helmet"
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import {navigate} from "gatsby-link";

const ContactPage = ({
                         data: {
                             site
                         },
                     }) => {
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const inputs = event.target.querySelectorAll("[name]");
        const unEncodedBody = Array.from(inputs).filter(input => input.name).reduce((accumulator, input) => {
            return {
                [input.name]: input.value,
                ...accumulator,
            }
        }, {})

        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({
                "form-name": event.target.getAttribute("name"),
                ...unEncodedBody,
            })
        }).then(() => navigate("/thank-you/")).catch(error => alert(error))
    }
    return (
        <Layout>
            <Helmet>
                <title>Contact — {site.siteMetadata.title}</title>
                <meta name="description" content={"Contact page of " + site.siteMetadata.description}/>
            </Helmet>
            <div className="two-grids -contact">
                <div className="post-thumbnail" style={{
                    backgroundImage: `url('/assets/alexander-andrews-HgUDpaGPTEA-unsplash.jpg')`,
                    marginBottom: 0
                }}>
                    <h1 className="post-title">Get in Touch</h1>
                    <p>Any questions or suggestions? Contact me.</p>
                </div>
                <div>
                    <form className="form-container" data-netlify="true" name="contact" method="post"
                          onSubmit={handleSubmit}>
                        <input type="hidden" name="form-name" value="contact"/>
                        <div>
                            <label htmlFor="visitorName">Name</label>
                            <input type="text" name="visitorName" id="visitorName"/>
                        </div>
                        <div>
                            <label htmlFor="visitorEmail">Email</label>
                            <input type="email" name="visitorEmail" id="visitorEmail"/>
                        </div>
                        <div>
                            <label htmlFor="contactFormSubject">Subject</label>
                            <input type="text" name="contactFormSubject" id="contactFormSubject"/>
                        </div>
                        <div>
                            <label htmlFor="contactFormMessage">Message</label>
                            <textarea name="contactFormMessage" id="contactFormMessage"></textarea>
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <input type="submit" className="button -primary" style={{marginRight: 0}}/>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
